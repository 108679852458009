import React from 'react';
import { CheckCircle, AlertCircle } from 'lucide-react';
import SEO from './SEO';

const Solution = () => {
    return (
        <>
            <SEO
                title="Our Solution - CV Ranking Tool Features"
                description="Learn about our advanced CV ranking solution. Discover how our AI-powered tool helps streamline your initial candidate screening process."
                keywords="cv ranking solution, AI recruitment, candidate screening, candidate matching, talent matching"
            />
            <div className="container mx-auto max-w-4xl px-6">
                <div className="bg-white rounded-xl shadow-lg border border-gray-100 py-8 md:py-12 px-6 md:px-12">
                    <div className="flex items-center mb-8">
                        <div className="w-2 h-12 bg-gradient-to-b from-indigo-600 to-blue-500 rounded-full mr-4" />
                        <h1 className="text-4xl font-bold text-gray-800">Our Solution</h1>
                    </div>
                    <p className="text-lg text-gray-700 leading-relaxed mb-8">
                        TalentRanker.ai provides an AI-powered CV ranking solution that enhances your initial screening process.
                        Our advanced algorithms analyze the job description and corresponding candidates' CVs to identify the most
                        qualified matches within seconds. This approach saves you time and improves hiring accuracy while ensuring
                        fairness across all candidates.
                    </p>
                    <div className="flex items-center mb-6">
                        <div className="w-2 h-12 bg-gradient-to-b from-indigo-600 to-blue-500 rounded-full mr-4" />
                        <h2 className="text-4xl font-bold text-gray-800">Steps</h2>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
                        <div className="bg-gray-50 rounded-lg p-6">
                            <div className="flex items-center mb-4">
                                <span className="mr-2 text-3xl font-bold text-gray-800">1.</span>
                                <CheckCircle className="h-7 w-7 text-green-500 mr-2" />
                                <h3 className="text-xl font-medium text-gray-800">Upload Job Description</h3>
                            </div>
                            <p className="text-lg text-gray-700 leading-relaxed">
                                Upload the job description file in a correctly formatted PDF format.
                            </p>
                        </div>
                        <div className="bg-gray-50 rounded-lg p-6">
                            <div className="flex items-center mb-4">
                                <span className="mr-2 text-3xl font-bold text-gray-800">2.</span>
                                <CheckCircle className="h-7 w-7 text-green-500 mr-2" />
                                <h3 className="text-xl font-medium text-gray-800">Specify Keywords</h3>
                            </div>
                            <p className="text-lg text-gray-700 leading-relaxed">
                                Provide the relevant keywords for the job description.
                            </p>
                        </div>
                        <div className="bg-gray-50 rounded-lg p-6">
                            <div className="flex items-center mb-4">
                                <span className="mr-2 text-3xl font-bold text-gray-800">3.</span>
                                <CheckCircle className="h-7 w-7 text-green-500 mr-2" />
                                <h3 className="text-xl font-medium text-gray-800">Upload CVs</h3>
                            </div>
                            <p className="text-lg text-gray-700 leading-relaxed">
                                Upload all the applicants' CVs in correctly formatted PDF files.
                            </p>
                        </div>
                        <div className="bg-gray-50 rounded-lg p-6">
                            <div className="flex items-center mb-4">
                                <AlertCircle className="h-7 w-7 text-yellow-500 mr-2" />
                                <h3 className="text-xl font-medium text-gray-800">Important Notes</h3>
                            </div>
                            <p className="text-lg text-gray-700 leading-relaxed">
                                - Do not refresh the page or press the back button while the system calculates the results. This may take a few minutes.
                                <br />
                                - Consider the results before leaving the results page.
                            </p>
                        </div>
                    </div>


                    <div className="flex items-center mb-6">
                        <div className="w-2 h-12 bg-gradient-to-b from-indigo-600 to-blue-500 rounded-full mr-4" />
                        <h2 className="text-4xl font-bold text-gray-800">Terms</h2>
                    </div>
                    <div className="bg-gray-50 rounded-lg p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        <div className="bg-white rounded-lg shadow-md p-6">
                            <div className="flex items-center mb-4">
                                <CheckCircle className="h-7 w-7 text-green-500 mr-2" />
                                <h3 className="text-xl font-medium text-gray-800">No Data Storage</h3>
                            </div>
                            <p className="text-lg text-gray-700 leading-relaxed">
                                We do not store received data and do not use cookies or tracking technologies. TalentRanker.ai does not require user accounts.
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-md p-6">
                            <div className="flex items-center mb-4">
                                <CheckCircle className="h-7 w-7 text-green-500 mr-2" />
                                <h3 className="text-xl font-medium text-gray-800">Pay-Per-Use Model</h3>
                            </div>
                            <p className="text-lg text-gray-700 leading-relaxed">
                                We use a Pay-Per-Use Model and calculate the fee based on the number of files uploaded for processing. We process all payments through Stripe. By using our service, you agree to Stripe's Terms and Privacy Policy.
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-md p-6">
                            <div className="flex items-center mb-4">
                                <AlertCircle className="h-7 w-7 text-yellow-500 mr-2" />
                                <h3 className="text-xl font-medium text-gray-800">Refund Policy</h3>
                            </div>
                            <p className="text-lg text-gray-700 leading-relaxed">
                                If the service fails to process your files, the paid amount will be automatically refunded. If the automatic refund is not successful, you can request a refund.
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-md p-6">
                            <div className="flex items-center mb-4">
                                <AlertCircle className="h-7 w-7 text-yellow-500 mr-2" />
                                <h3 className="text-xl font-medium text-gray-800">File Requirements</h3>
                            </div>
                            <p className="text-lg text-gray-700 leading-relaxed">
                                You are responsible for ensuring that the uploaded files are accurate and complete and each file has a unique name. You agree that the uploaded files do not contain any illegal or harmful content and comply with all applicable laws.
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-md p-6">
                            <div className="flex items-center mb-4">
                                <AlertCircle className="h-7 w-7 text-yellow-500 mr-2" />
                                <h3 className="text-xl font-medium text-gray-800">PDF File Parsing</h3>
                            </div>
                            <p className="text-lg text-gray-700 leading-relaxed">
                                The system ignores CVs shorter than 250 words or longer than six pages. The system does its best to parse all the uploaded CVs. However, some PDF files, partially or in total, are not parsable. In particular, this can occur if the font used in the PDF file lacks a proper Unicode mapping for the glyph used in the file.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Solution;