import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="container mx-auto px-6 py-10">
        <div className="flex flex-col items-center">
          <div className="flex space-x-8 mb-6">
            <Link 
              to="/solution" 
              className="text-gray-700 hover:text-indigo-600 transition-colors font-medium"
            >
              Solution
            </Link>
            <Link 
              to="/about" 
              className="text-gray-700 hover:text-indigo-600 transition-colors font-medium"
            >
              About Us
            </Link>
          </div>
          <div className="text-gray-600 text-sm font-medium">
            © {currentYear} TalentRanker.ai, All rights reserved.
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-r from-indigo-600 to-blue-500 h-1" />
    </footer>
  );
};

export default Footer;