import React, { useState, useCallback, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import JSZip from 'jszip';
import { UploadCloud, Check, AlertCircle, RefreshCw, ArrowLeft, ArrowRight } from 'lucide-react';
import { jsPDF } from 'jspdf';
import { Clock, Shield, Zap } from 'lucide-react';
import RankedResults from './RankedResults';

const stripePromise = loadStripe('pk_live_51Q5CS6CZyPysG8FLJY9tagKy2o9SGp2RIpFUN6xFiM3EqGYYXvYX7P7YMQhJMq8ggnb6fRLhEmhErsYVeVTPwRzZ005T3n8imx');

const PaymentForm = ({ onPaymentSuccess, isProcessing }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);
    const [requiresRedirect, setRequiresRedirect] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setProcessing(true);
        setError('');
        setRequiresRedirect(false);

        try {
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {},
                redirect: 'if_required',
            });

            if (error) {
                // Check for authentication errors that require redirect
                if (
                    error.type === 'card_error' &&
                    error.code === 'authentication_required' &&
                    error.payment_method?.type === 'card' &&
                    error.payment_method?.card?.three_d_secure?.authentication_flow === 'redirect'
                ) {
                    setRequiresRedirect(true);
                    throw new Error(
                        'This payment requires redirect-based authentication. To keep our solution as fast ' +
                        'as possible, we don\'t support this. Please use a different card.'
                    );
                }
                throw new Error(error.message);
            }

            if (paymentIntent && paymentIntent.status === 'succeeded') {
                onPaymentSuccess();
            } else {
                throw new Error('Payment failed');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setProcessing(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="mt-6">
            <PaymentElement className="mb-4" />
            {!isProcessing ? (
                <button
                    type="submit"
                    disabled={!stripe || processing}
                    className="w-full bg-indigo-600 text-white py-3 px-4 rounded-md hover:bg-indigo-700 transition duration-300 disabled:opacity-50 font-semibold shadow-md"
                >
                    {processing ? 'Processing...' : 'Pay and Process CVs'}
                </button>
            ) : (
                <div className="w-full bg-yellow-100 border border-yellow-400 text-yellow-700 p-4 rounded-md flex items-center justify-center">
                    <RefreshCw className="animate-spin mr-2" size={20} />
                    <p>Uploading and processing CVs. Please stay on this page...</p>
                </div>
            )}
            {error && (
                <div className={`mt-4 p-3 ${requiresRedirect ? 'bg-yellow-100 border-yellow-400 text-yellow-700' : 'bg-red-100 border-red-400 text-red-700'} rounded-md flex items-center`}>
                    <AlertCircle className="mr-2" size={20} />
                    <p>{error}</p>
                </div>
            )}
        </form>
    );
};

const FeatureCards = () => (
    <div className="bg-gradient-to-b from-white to-gray-50 py-6 border-b border-gray-200">
        <div className="max-w-6xl mx-auto px-8">
            <div className="text-center mb-6">
                <h1 className="text-3xl font-bold text-gray-900 mb-2">
                    AI-Powered Talent Ranking
                </h1>
                <p className="text-gray-600">
                    Rank up to 100 CVs for any job opening within seconds
                </p>
            </div>
            <div className="grid grid-cols-3 gap-4 text-center">
                <div className="flex flex-col items-center p-4">
                    <Zap className="w-6 h-6 text-indigo-600 mb-2" />
                    <h3 className="text-sm font-semibold text-gray-800 mb-1">Efficient Processing</h3>
                    <p className="text-xs text-gray-600">
                        AI-powered matching for quality shortlists
                    </p>
                </div>
                <div className="flex flex-col items-center p-4">
                    <Shield className="w-6 h-6 text-indigo-600 mb-2" />
                    <h3 className="text-sm font-semibold text-gray-800 mb-1">Unbiased Evaluation</h3>
                    <p className="text-xs text-gray-600">
                        Standardized evaluation across applicants
                    </p>
                </div>
                <div className="flex flex-col items-center p-4">
                    <Clock className="w-6 h-6 text-indigo-600 mb-2" />
                    <h3 className="text-sm font-semibold text-gray-800 mb-1">Time-Saving</h3>
                    <p className="text-xs text-gray-600">
                        Process applications quickly & accurately
                    </p>
                </div>
            </div>
        </div>
    </div>
);

const ConfirmDialog = ({ isOpen, onConfirm, onCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
                <h3 className="text-lg font-semibold mb-4">Are you sure?</h3>
                <p className="text-gray-600 mb-6">
                    You are about to leave the results page. This action cannot be undone and you will lose access to the current results.
                </p>
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onCancel}
                        className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                    >
                        Leave Page
                    </button>
                </div>
            </div>
        </div>
    );
};

const MultiStepForm = ({ step, setStep }) => {
    const [jobDescription, setJobDescription] = useState(null);
    const [originalJobDescriptionName, setOriginalJobDescriptionName] = useState('');
    const [keywords, setKeywords] = useState('');
    const [keywordsPdf, setKeywordsPdf] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [price, setPrice] = useState(0);
    const [clientSecret, setClientSecret] = useState('');
    const [processedDocs, setProcessedDocs] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingReset, setPendingReset] = useState(false);

    // Separate state for each step's status
    const [jobDescriptionStatus, setJobDescriptionStatus] = useState('');
    const [keywordsStatus, setKeywordsStatus] = useState('');
    const [cvUploadStatus, setCvUploadStatus] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [processStatus, setProcessStatus] = useState('');

    const resetForm = useCallback(() => {
        setStep(1);
        setJobDescription(null);
        setOriginalJobDescriptionName('');
        setKeywords('');
        setKeywordsPdf(null);
        setSelectedFiles([]);
        setPrice(0);
        setClientSecret('');
        setProcessedDocs([]);
        setIsUploading(false);
        setJobDescriptionStatus('');
        setKeywordsStatus('');
        setCvUploadStatus('');
        setPaymentStatus('');
        setProcessStatus('');

        if (document.getElementById('job-description-upload')) {
            document.getElementById('job-description-upload').value = '';
        }
        if (document.getElementById('file-upload')) {
            document.getElementById('file-upload').value = '';
        }
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (step === 6 && processedDocs.length > 0) {
                e.preventDefault();
                e.returnValue = '';
                return '';
            }
        };

        if (step === 6 && processedDocs.length > 0) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [step, processedDocs]);

    // Modify resetForm to handle confirmation
    const handleResetRequest = useCallback(() => {
        if (step === 6 && processedDocs.length > 0) {
            setShowConfirmDialog(true);
            setPendingReset(true);
        } else {
            resetForm();
        }
    }, [step, processedDocs, resetForm]);

    const handleConfirmReset = useCallback(() => {
        setShowConfirmDialog(false);
        if (pendingReset) {
            resetForm();
            setPendingReset(false);
        }
    }, [pendingReset, resetForm]);

    const handleCancelReset = useCallback(() => {
        setShowConfirmDialog(false);
        setPendingReset(false);
    }, []);

    const createPaymentIntent = useCallback(async (filesCount) => {
        try {
            const { data } = await axios.post('https://api.talentranker.ai/create-payment-intent', {
                filesCount: filesCount
            });
            setClientSecret(data.clientSecret);
        } catch (err) {
            setPaymentStatus('Failed to create payment intent: ' + err.message);
        }
    }, []);

    const calculatePriceAndCreateIntent = useCallback(async () => {
        if (jobDescription && keywords && selectedFiles.length > 0) {
            const newPrice = (0.30 + selectedFiles.length * 0.05).toFixed(2);
            setPrice(parseFloat(newPrice));
            await createPaymentIntent(selectedFiles.length);
        } else {
            setPrice(0);
            setClientSecret('');
        }
    }, [jobDescription, keywords, selectedFiles, createPaymentIntent]);

    const handleJobDescriptionChange = useCallback((event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf' && file.size < 2 * 1024 * 1024) {
            const renamedFile = new File([file], 'job_description.pdf', { type: 'application/pdf' });
            setJobDescription(renamedFile);
            setOriginalJobDescriptionName(file.name);
            setJobDescriptionStatus('Job description uploaded successfully');
        } else {
            setJobDescription(null);
            setOriginalJobDescriptionName('');
            setJobDescriptionStatus('Please select a PDF file less than 2 MB in size for the job description');
            event.target.value = null;
        }
    }, []);

    const handleKeywordsChange = useCallback((event) => {
        const newValue = event.target.value;
        // Automatically replace multiple spaces with single space
        const cleanedValue = newValue.replace(/\s+/g, ' ');
        setKeywords(cleanedValue);

        const invalidCharsRegex = /[^a-zA-Z0-9,\s]/g;
        if (invalidCharsRegex.test(cleanedValue)) {
            setKeywordsStatus('Only letters, numbers, commas, and spaces are allowed');
            return;
        }

        const multipleSpacesRegex = /\s{2,}/;
        if (multipleSpacesRegex.test(cleanedValue)) {
            setKeywordsStatus('Only single spaces between words are allowed');
            return;
        }

        const keywordsList = cleanedValue.trim()
            .split(',')
            .map(k => k.trim())
            .filter(k => k.length > 0);

        if (keywordsList.length > 30) {
            setKeywordsStatus('Maximum 30 keywords allowed');
            return;
        }

        const invalidKeywords = keywordsList.filter(keyword =>
            keyword.split(/\s+/).length > 5
        );

        if (invalidKeywords.length > 0) {
            setKeywordsStatus('Each keyword must not exceed 5 words');
            return;
        }

        if (cleanedValue.trim()) {
            setKeywordsStatus('Keywords entered');
        } else {
            setKeywordsStatus('');
        }
    }, []);

    useEffect(() => {
        if (keywords) {
            const pdf = new jsPDF();
            pdf.text(keywords, 10, 10);
            const pdfBlob = pdf.output('blob');
            setKeywordsPdf(new File([pdfBlob], 'keywords.pdf', { type: 'application/pdf' }));
        }
    }, [keywords]);

    const handleFileChange = useCallback((event) => {
        const files = Array.from(event.target.files);

        if (files.length < 5 || files.length > 100) {
            setCvUploadStatus('Please select between 5 and 100 files.');
            setSelectedFiles([]);
            return;
        }

        const allPdfFiles = files.every(file => file.type === 'application/pdf');
        if (!allPdfFiles) {
            setCvUploadStatus('Please ensure all files are in PDF format.');
            setSelectedFiles([]);
            return;
        }

        const invalidFiles = files.filter(file => file.size > 1 * 1024 * 1024);
        if (invalidFiles.length > 0) {
            const invalidFileNames = invalidFiles.map(file => file.name);
            setCvUploadStatus(`Please ensure each file is less than 1 MB in size. The following files exceed the limit: ${invalidFileNames.join(', ')}`);
            setSelectedFiles([]);
            return;
        }

        const totalSize = files.reduce((acc, file) => acc + file.size, 0);
        if (totalSize > 16 * 1024 * 1024) {
            setCvUploadStatus('Please ensure the total file size does not exceed 16 MB limit.');
            setSelectedFiles([]);
            return;
        }

        setSelectedFiles(files);
        setCvUploadStatus(`${files.length} CVs uploaded successfully`);
    }, []);

    const createZipArchive = useCallback(async (files) => {
        const zip = new JSZip();

        for (let file of files) {
            const arrayBuffer = await file.arrayBuffer();
            zip.file(file.name, arrayBuffer);
        }

        return await zip.generateAsync({ type: 'blob' });
    }, []);

    const handlePaymentSuccess = useCallback(async () => {
        setIsUploading(true);
        setPrice(0);

        try {
            const allFiles = [jobDescription, keywordsPdf, ...selectedFiles].filter(Boolean);
            const zipBlob = await createZipArchive(allFiles);

            const formData = new FormData();
            formData.append('zipFile', zipBlob, 'archive.zip');

            const response = await axios.post(`https://api.talentranker.ai/talent/${clientSecret}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data && Array.isArray(response.data.cvs)) {
                setProcessedDocs(response.data.cvs);
                setProcessStatus(`${response.data.cvs.length} CVs successfully ranked`);
                setStep(6); // Move to results step
            } else {
                throw new Error('Received an unexpected response format from the server.');
            }
        } catch (error) {
            setProcessedDocs([]); // Clear any previous results
            setStep(6); // Move to results step to show error and restart button

            if (error.response) {
                if (error.response.status === 500) {
                    setProcessStatus(
                        'We apologize, but we encountered a technical issue while processing your request. ' +
                        'Your payment has been refunded. Please try again.'
                    );
                } else if (error.response.status === 400) {
                    setProcessStatus(
                        'Your request could not be processed due to missing or invalid files. ' +
                        'Please ensure both the job description and keywords files are properly uploaded and all the CVs are in correct PDF format.'
                    );
                } else {
                    setProcessStatus('An error occurred while processing your request: ' +
                        (error.response.data?.message || 'Unknown error'));
                }
            } else if (error.request) {
                // Handle network errors
                setProcessStatus(
                    'Unable to connect to the server. Please check your internet connection and try again.'
                );
            } else {
                // Handle other errors
                setProcessStatus('An unexpected error occurred: ' + error.message);
            }
        } finally {
            setIsUploading(false);
        }
    }, [clientSecret, selectedFiles, jobDescription, keywordsPdf, createZipArchive]);

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <>
                        <h2 className="text-2xl font-bold mb-6 text-gray-800">Step 1: Upload Job Description</h2>
                        <div className="mb-6">
                            <label htmlFor="job-description-upload" className="block text-sm font-medium text-gray-700 mb-2">
                                Upload Job Description (PDF file) *
                            </label>
                            <input
                                id="job-description-upload"
                                type="file"
                                onChange={handleJobDescriptionChange}
                                accept=".pdf"
                                required
                                className="block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-blue-50 file:text-blue-700
                  hover:file:bg-blue-100
                  border border-gray-300 rounded-md shadow-sm"
                            />
                            {jobDescription && (
                                <p className="mt-2 text-sm text-gray-600">Current file: {originalJobDescriptionName}</p>
                            )}
                        </div>
                        {renderStepStatus(jobDescriptionStatus)}
                    </>
                );
            case 2:
                return (
                    <>
                        <h2 className="text-2xl font-bold mb-6 text-gray-800">Step 2: Enter Keywords</h2>
                        <div className="mb-6">
                            <label htmlFor="keywords-input" className="block text-sm font-medium text-gray-700 mb-2">
                                Enter Keywords *
                            </label>
                            <textarea
                                id="keywords-input"
                                value={keywords}
                                onChange={handleKeywordsChange}
                                placeholder="Enter keywords (e.g., skill1, skill2, skill3, location, senior, ..)"
                                required
                                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500 shadow-sm"
                                rows="3"
                            />
                            <p className="mt-2 text-sm text-gray-500">
                                Please enter a maximum of 30 comma-separated keywords for the selected job
                            </p>
                        </div>
                        {renderStepStatus(keywordsStatus)}
                    </>
                );
            case 3:
                return (
                    <>
                        <h2 className="text-2xl font-bold mb-6 text-gray-800">Step 3: Upload CV Files</h2>
                        <div className="mb-6">
                            <label htmlFor="file-upload" className="block text-sm font-medium text-gray-700 mb-2">
                                Upload CV Files (5-100 PDF files, max 20MB total) *
                            </label>
                            <label htmlFor="file-upload" className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition duration-300">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <UploadCloud className="w-10 h-10 mb-3 text-gray-400" />
                                    <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                    <p className="text-xs text-gray-500">PDF files (5-100 files, max 20MB total)</p>
                                </div>
                                <input
                                    id="file-upload"
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                    accept=".pdf"
                                    className="hidden"
                                />
                            </label>
                            {selectedFiles.length > 0 && (
                                <p className="mt-2 text-sm text-gray-600">{selectedFiles.length} files selected</p>
                            )}
                        </div>
                        {renderStepStatus(cvUploadStatus)}
                    </>
                );
            case 4:
                return (
                    <>
                        <h2 className="text-2xl font-bold mb-6 text-gray-800">Step 4: Review and Payment</h2>
                        <div className="bg-gray-50 rounded-lg p-6 shadow-sm mb-6">
                            <div className="mb-4">
                                <h3 className="font-semibold text-gray-700">Job Description:</h3>
                                <p className="text-gray-600">{jobDescription ? jobDescription.name : 'Not uploaded'}</p>
                            </div>
                            <div className="mb-4">
                                <h3 className="font-semibold text-gray-700">Keywords:</h3>
                                <p className="text-gray-600">{keywords || 'Not provided'}</p>
                            </div>
                            <div className="mb-4">
                                <h3 className="font-semibold text-gray-700">CV Files:</h3>
                                <p className="text-gray-600">{selectedFiles.length} files uploaded</p>
                            </div>
                            <div className="p-3 bg-blue-100 border border-blue-400 text-blue-700 rounded-md">
                                <p className="font-semibold">Total Price: €{price}</p>
                            </div>
                        </div>
                    </>
                );
            case 5:
                return (
                    <>
                        <h2 className="text-2xl font-bold mb-6 text-gray-800">Step 5: Payment</h2>
                        {clientSecret && (
                            <Elements
                                stripe={stripePromise}
                                options={{
                                    clientSecret: clientSecret,
                                    appearance: {
                                        theme: 'stripe',
                                    },
                                    paymentMethodOrder: ['card'],
                                }}
                            >
                                <PaymentForm
                                    onPaymentSuccess={handlePaymentSuccess}
                                    isProcessing={isUploading}
                                />
                            </Elements>
                        )}
                        {renderStepStatus(paymentStatus)}
                    </>
                );
            case 6:
                return (
                    <>
                        <div className={`mb-6 p-4 rounded-lg ${processedDocs.length > 0
                            ? 'bg-green-50 border border-gray-200'
                            : 'bg-red-50 border border-red-200'
                            }`}>
                            <h2 className={`text-2xl font-bold ${processedDocs.length > 0 ? 'text-gray-800' : 'text-red-800'
                                }`}>
                                {processStatus}
                            </h2>
                        </div>

                        <button
                            onClick={handleResetRequest}
                            className="mb-6 w-full bg-indigo-600 text-white py-3 px-4 rounded-md hover:bg-indigo-700 transition duration-300 flex items-center justify-center font-semibold shadow-md"
                        >
                            <RefreshCw className="mr-2" size={20} />
                            Rank New CVs
                        </button>

                        {processedDocs.length > 0 ? (
                            <div className="bg-white rounded-lg p-6 shadow-sm w-full">
                                <RankedResults processedDocs={processedDocs} />
                            </div>
                        ) : (
                            <div className="bg-gray-50 rounded-lg p-6 shadow-sm">
                                <div className="flex items-center justify-center">
                                    <AlertCircle className="text-red-500 mr-2" size={24} />
                                    <p className="text-gray-700">No results available</p>
                                </div>
                            </div>
                        )}

                        <ConfirmDialog
                            isOpen={showConfirmDialog}
                            onConfirm={handleConfirmReset}
                            onCancel={handleCancelReset}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    const renderStepStatus = (status) => {
        if (!status) return null;

        const isError = status.toLowerCase().includes('error') || status.toLowerCase().includes('failed') || status.toLowerCase().includes('please') || status.toLowerCase().includes('allowed') || status.toLowerCase().includes('must not');

        return (
            <div className={`mt-4 p-3 ${isError ? 'bg-red-100 border-red-400 text-red-700' : 'bg-green-100 border-green-400 text-green-700'} rounded-md flex items-center`}>
                {isError ? <AlertCircle className="mr-2" size={20} /> : <Check className="mr-2" size={20} />}
                <p>{status}</p>
            </div>
        );
    };

    const canProceed = () => {
        switch (step) {
            case 1:
                return !!jobDescription;
            case 2:
                return keywords.trim().length > 0;
            case 3:
                return selectedFiles.length >= 5 && selectedFiles.length <= 100;
            case 4:
                return true; // Can always proceed from review step
            default:
                return false;
        }
    };

    const handleNext = () => {
        if (canProceed()) {
            if (step === 3) {
                calculatePriceAndCreateIntent();
            }
            setStep(step + 1);
        }
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    return (
        <div className="max-w-4xl mx-auto px-8">
            <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-8">
                {renderStepContent()}
                {step < 5 && (
                    <div className="mt-8 flex justify-between">
                        {step > 1 && (
                            <button
                                onClick={handleBack}
                                className="bg-gray-200 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-300 transition duration-300 flex items-center font-medium"
                            >
                                <ArrowLeft className="mr-2" size={20} />
                                Back
                            </button>
                        )}
                        {step < 4 && (
                            <button
                                onClick={handleNext}
                                disabled={!canProceed()}
                                className="ml-auto bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed flex items-center font-medium"
                            >
                                {step === 1 ? "Choose Keywords" : step === 2 ? "Upload CVs" : "Review"}
                                <ArrowRight className="ml-2" size={20} />
                            </button>
                        )}
                        {step === 4 && (
                            <button
                                onClick={handleNext}
                                className="ml-auto bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300 flex items-center font-medium"
                            >
                                Proceed to Payment
                                <ArrowRight className="ml-2" size={20} />
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const MultiStepUploadForm = () => {
    const [step, setStep] = useState(1);

    return (
        <div className="min-h-screen bg-white">
            <FeatureCards />
            <main className="py-6">
                <div className="max-w-6xl mx-auto px-8">
                    <div className="bg-white">
                        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">
                            {/* Conditionally render title based on the current step */}
                            {step < 6 && "Start Ranking Your CVs..."}
                        </h2>
                        <MultiStepForm step={step} setStep={setStep} />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default MultiStepUploadForm;