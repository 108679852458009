import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './Layout';
import MultiStepUploadForm from './MultiStepUploadForm';
import Solution from './Solution';
import About from './About';
import SEO from './SEO';

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<HomeWrapper />} />
            <Route path="/solution" element={<Solution />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Layout>
      </Router>
    </HelmetProvider>
  );
};

// New HomeWrapper component with enhanced styling
const HomeWrapper = () => {
  return (
    <>
      <SEO
        title="Talent Ranking Tool - Rank Talents based on their CVs"
        description="Upload your job description and CVs to get instant AI-powered ranking. Find the best candidates efficiently with our automated CV analysis tool."
        keywords="cv ranking, candidate screening, ranking talents for job descriptions, recruitment tool"
      />
      <div className="container mx-auto max-w-2xl px-6">
        <div className="bg-white rounded-xl shadow-lg border border-gray-100">
          <div className="bg-gradient-to-r from-indigo-600 to-blue-500 h-2 rounded-t-xl" />
          <MultiStepUploadForm />
        </div>
      </div>
    </>
  );
};

export default App;