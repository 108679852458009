import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Header />
      <main className="flex-grow py-12 bg-gradient-to-b from-gray-50 via-white to-gray-50">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;