import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({
  title,
  description, 
  keywords,
  canonicalUrl,
  ogType = 'website',
}) => {
  const siteUrl = 'https://www.talentranker.ai';
  const defaultTitle = 'CV Ranking Tool - Analyze and Rank CVs Efficiently';
  const defaultDescription = 'Upload your job description and CVs to get AI-powered ranking and analysis. Find the best candidates faster with our automated CV ranking tool.';
  const defaultKeywords = 'cv ranking, talent ranking, resume analysis, talent matching, recruitment tool, AI recruitment';
  
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta name="keywords" content={keywords || defaultKeywords} />
      
      {/* Canonical URL */}
      <link rel="canonical" href={canonicalUrl || siteUrl} />
      
      {/* Open Graph Tags */}
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={canonicalUrl || siteUrl} />
      
      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || defaultTitle} />
      <meta name="twitter:description" content={description || defaultDescription} />
    </Helmet>
  );
};

export default SEO;