import React from 'react';
import { Star, StarHalf, StarOff } from 'lucide-react';

const RankedResult = ({ title, rank }) => {
  const getRankStyles = (rank) => {
    switch (rank) {
      case 'HIGHEST':
        return {
          container: 'bg-green-50 border-green-200',
          icon: <Star className="text-green-500 mr-3" size={20} />,
          text: 'text-green-800'
        };
      case 'HIGH':
        return {
          container: 'bg-blue-50 border-blue-200',
          icon: <StarHalf className="text-blue-500 mr-3" size={20} />,
          text: 'text-blue-800'
        };
      case 'LOW':
        return {
          container: 'bg-orange-50 border-orange-200',
          icon: <StarOff className="text-orange-500 mr-3" size={20} />,
          text: 'text-orange-800'
        };
      case 'LOWEST':
        return {
          container: 'bg-red-50 border-red-200',
          icon: <StarOff className="text-red-500 mr-3" size={20} />,
          text: 'text-red-800'
        };
      default:
        return {
          container: 'bg-gray-50 border-gray-200',
          icon: null,
          text: 'text-gray-800'
        };
    }
  };

  const styles = getRankStyles(rank);

  return (
    <div className={`flex items-center p-4 rounded-lg border ${styles.container} transition-all duration-200 hover:shadow-md`}>
      <div className="flex items-center flex-1">
        {styles.icon}
        <span className={`${styles.text} font-medium`}>{title}</span>
      </div>
    </div>
  );
};

const RankHeader = ({ rank, count }) => {
  const getHeaderStyles = (rank) => {
    switch (rank) {
      case 'HIGHEST':
        return {
          container: 'bg-green-100',
          text: 'text-green-800',
          icon: <Star className="text-green-600" size={24} />
        };
      case 'HIGH':
        return {
          container: 'bg-blue-100',
          text: 'text-blue-800',
          icon: <StarHalf className="text-blue-600" size={24} />
        };
      case 'LOW':
        return {
          container: 'bg-orange-100',
          text: 'text-orange-800',
          icon: <StarOff className="text-orange-600" size={24} />
        };
      case 'LOWEST':
        return {
          container: 'bg-red-100',
          text: 'text-red-800',
          icon: <StarOff className="text-red-600" size={24} />
        };
      default:
        return {
          container: 'bg-gray-100',
          text: 'text-gray-800',
          icon: null
        };
    }
  };

  const styles = getHeaderStyles(rank);
  const displayRank = rank.charAt(0) + rank.slice(1).toLowerCase();

  return (
    <div className={`flex items-center justify-between p-4 rounded-t-lg ${styles.container}`}>
      <div className="flex items-center space-x-3">
        {styles.icon}
        <h3 className={`text-lg font-semibold ${styles.text}`}>
          {displayRank} Match
        </h3>
      </div>
      <span className={`px-3 py-1 rounded-full text-sm font-medium ${styles.text} bg-white/50`}>
        {count} {count === 1 ? 'Match' : 'Matches'}
      </span>
    </div>
  );
};

const RankedResults = ({ processedDocs }) => {
  const groupedDocs = processedDocs.reduce((acc, doc) => {
    const rank = doc._2;
    if (!acc[rank]) {
      acc[rank] = [];
    }
    acc[rank].push(doc);
    return acc;
  }, {});

  const rankOrder = ['HIGHEST', 'HIGH', 'LOW', 'LOWEST'];

  return (
    <div className="space-y-6">
      {rankOrder.map(rank => {
        const docs = groupedDocs[rank] || [];
        if (docs.length === 0) return null;

        return (
          <div key={rank} className="rounded-lg overflow-hidden border border-gray-200">
            <RankHeader rank={rank} count={docs.length} />
            <div className="divide-y divide-gray-200">
              {docs.map((doc, index) => (
                <RankedResult
                  key={`${rank}-${index}`}
                  title={doc._1}
                  rank={doc._2}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RankedResults;