import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  
  const isActive = (path) => {
    return location.pathname === path ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-700';
  };

  return (
    <header className="bg-white shadow-md">
      <div className="bg-gradient-to-r from-indigo-600 to-blue-500 h-1" />
      <nav className="container mx-auto px-6 py-5">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold">
            <span className="text-indigo-600">TalentRanker</span>
            <span className="text-blue-500">.ai</span>
          </Link>
          <div className="space-x-8">
            <Link 
              to="/solution" 
              className={`${isActive('/solution')} hover:text-indigo-600 transition-colors font-medium pb-1`}
            >
              Solution
            </Link>
            <Link 
              to="/about" 
              className={`${isActive('/about')} hover:text-indigo-600 transition-colors font-medium pb-1`}
            >
              About Us
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;